<template>
  <div>
    <validation-observer ref="simpleRules">
      <personal />
      <kvkk />
      <related-persons />
      <invoice v-if="customer.detailForm" />
      <contact />
      <other />
      <save-button :action-methods="submitForm" />
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Personal from '@/views/Customers/components/Personal.vue'
import Kvkk from '@/views/Customers/components/Kvkk.vue'
import Invoice from '@/views/Customers/components/Invoice.vue'
import Contact from '@/views/Customers/components/Contact.vue'
import Other from '@/views/Customers/components/Other.vue'
import SaveButton from '@/views/Customers/forms/SaveButton.vue'
import RelatedPersons from '@/views/Customers/components/RelatedPersons.vue'

export default {
  name: 'CustomersEdit',
  components: {
    RelatedPersons,
    ValidationObserver,
    Personal,
    Kvkk,
    Invoice,
    Contact,
    Other,
    SaveButton,
  },
  data() {
    return {
      required,
      locale: 'tr',
      submitStatus: false,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    saveStatus() {
      return this.$store.getters['customers/getCustomerSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.customer.submitStatus = false
    },
    customer(val) {
      if (val.id) {
        this.loadedData()
      }
    },
  },
  created() {
    localize(this.locale)
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
    loadedData() {
      if (this.customer.id) {
        if (this.customer.id_com_city) {
          this.$store.dispatch('districts/districtsList', {
            select: ['com_district.id AS id', 'com_district.title AS title'],
            where: {
              id_com_city: this.customer.id_com_city,
            },
          })
        }
        if (this.customer.id_com_district) {
          this.$store.dispatch('regions/regionsList', {
            select: ['com_region.id AS id', 'com_region.title AS title'],
            where: {
              id_com_district: this.customer.id_com_district,
            },
          })
        }
        if (this.customer.id_com_region) {
          this.$store.dispatch('neighborhoods/neighborhoodsList', {
            select: ['com_neighborhood.id AS id', 'com_neighborhood.title AS title'],
            where: {
              id_com_region: this.customer.id_com_region,
            },
          })
        }
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitStatus = true
          this.customer.submitStatus = true
          this.$store.dispatch('customers/customerSave', this.customer)
            .then(() => {
              this.getCustomer()
            })
        }
      })
    },
  },
}
</script>
